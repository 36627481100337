import { getPDPUrl } from 'utils/product';

import { Link } from 'react-router-dom';

export const routerLinkWithProps = linkProps => ({
  component: Link,
  componentProps: linkProps,
});

/**
 * When we have fully migrated to the MFE version of the PDP including for
 * logged-in users, remove this logic and always use regular anchor links.
 */
export const getProductLinkProps = (isUserLoggedIn, productName, productId) => {
  const pdpUrl = getPDPUrl(productId, productName);

  return isUserLoggedIn
    ? // If user is logged in: do SPA navigations to the Monolith version of the PDP,
      // until the MFE version supports logged-in users.
      routerLinkWithProps({
        to: pdpUrl,
      })
    : // If user is logged out: hard page load so we can potentially load the MFE
      // version of the PDP.
      { href: pdpUrl };
};
