import React from 'react';
import { useMakeSelector } from 'hooks/use-make-selector';
import {
  makeGetCategoryParentInfoByProductId,
  makeGetProductById,
} from 'redux/modules/entities/selectors/products';
import { getLocation, isSuppressAlternatives } from 'redux/modules/routing/selectors';
import { getIsFavourite } from 'redux/modules/favourites-products/selectors';
import { isSponsoredProduct } from 'redux/modules/search-and-browse/selectors';
import { getPageType } from 'utils/get-page-type';
import { getIsProductBoosted } from 'redux/modules/favourites/selectors/get-is-product-boosted';
import { useExperiments } from 'components/Experiment/useExperiments';
import { POD_VIEW_ALTERNATIVES } from 'constants/monetateExperiments';

import classNames from 'classnames';
import AnchorLink from 'components/AnchorLink';
import { useSelector } from 'react-redux';
import { handleProductListClick } from 'utils/gtm';
import styles from './styles.scss';

export interface ViewAlternativesProps {
  productId: string;
  className?: string;
  conflictMessage: string;
  position: number;
}

export const trackViewAlternativesClick =
  (
    productData: unknown,
    analyticsData: {
      isFavourite: boolean;
      position: number;
      sponsored: boolean;
      conflictMessage: string;
      wrapperId: string;
      boosted: boolean;
    },
  ) =>
  () => {
    handleProductListClick({
      conflictMessage: analyticsData?.conflictMessage?.toLowerCase(),
      isFavourite: analyticsData?.isFavourite,
      productData,
      searchType: analyticsData?.wrapperId,
      wrapperId: analyticsData?.wrapperId,
      position: analyticsData?.position,
      boosted: analyticsData?.boosted,
      sponsored: analyticsData?.sponsored,
      viewAlternativesClick: true,
    });
  };

const ViewAlternatives = ({
  productId,
  className = undefined,
  conflictMessage,
  position,
}: ViewAlternativesProps) => {
  const { id, url } = useMakeSelector(makeGetCategoryParentInfoByProductId, productId);
  const { pathname } = useSelector(getLocation);
  const suppressDisplayLink = useSelector(isSuppressAlternatives);
  const productData = useMakeSelector(makeGetProductById, productId);
  const { lineNumber } = productData;
  const analyticsData = {
    // eslint-disable-next-line
    // @ts-ignore
    isFavourite: useSelector(state => getIsFavourite(state, lineNumber)),
    position,
    // eslint-disable-next-line
    // @ts-ignore
    sponsored: useSelector(state => isSponsoredProduct(state, lineNumber)),
    conflictMessage,
    wrapperId: getPageType(pathname),
    // eslint-disable-next-line
    // @ts-ignore
    boosted: useSelector(state => getIsProductBoosted(state, lineNumber)),
  };

  const display = id && pathname !== url && !suppressDisplayLink;

  const { getDecisionById } = useExperiments();
  const viewSimilar =
    getDecisionById(POD_VIEW_ALTERNATIVES.experiment).variant ===
    POD_VIEW_ALTERNATIVES.variant.viewSimilar;

  const VIEW_ALTERNATIVES_LABEL = viewSimilar ? 'View similar' : 'View alternatives';

  return display ? (
    <AnchorLink
      className={classNames(styles.viewAlternatives, className)}
      to={url}
      onClick={trackViewAlternativesClick(productData, analyticsData)}
    >
      {VIEW_ALTERNATIVES_LABEL}
    </AnchorLink>
  ) : null;
};

export default ViewAlternatives;
