import { createSelector } from 'reselect';
import get from 'lodash/get';

import urls from 'constants/urls';

import { pathJoin } from 'utils/general';
import { getLocation } from 'redux/modules/routing/selectors';

import { getGridItemProducts } from 'redux/modules/search-and-browse/selectors';
import { getPromotionById } from 'redux/modules/entities/selectors/promotions';
import { getSearchAndBrowseSearchType } from 'redux/modules/search-and-browse/selectors/search';

const getMatchedOfferId = (state, { params: { offerId } = {} } = {}) => offerId;

const getSearchAndBrowseActivePromotion = createSelector(
  [({ searchAndBrowse } = {}) => searchAndBrowse],
  ({ activePromotion } = {}) => activePromotion,
);

export const getCurrentPromotionPage = createSelector(
  [
    state => getLocation(state)?.pathname,
    (_state, promotion) => get(promotion, 'promotionId', null),
  ],
  (location, promotionId) => {
    return promotionId ? location === pathJoin(urls.offerDetails, promotionId.toString()) : false;
  },
);

export const getOfferDetailsPromotionId = createSelector(
  [getSearchAndBrowseActivePromotion, getMatchedOfferId],
  (activePromotionId, matchedId) => activePromotionId || matchedId,
);

export const getOfferDetailsPromotion = createSelector(
  [getSearchAndBrowseSearchType, getGridItemProducts, getOfferDetailsPromotionId, state => state],
  (searchType, products, promotionId, state) =>
    searchType === 'Offer' && products.length > 0
      ? getPromotionById(state, promotionId)
      : undefined,
);
