import { citrusAdImpressions, metadataAdImpressions } from 'analytics/gtmServerFunctions';
import { impressionsService } from 'analytics/product-impressions-tracking';
import { usePodObserver } from './use-pod-observer';

export function usePodImpression(productData, sponsored) {
  return usePodObserver(elem => {
    const { sponsorshipId, metadata } = productData;
    if (sponsorshipId) citrusAdImpressions(sponsorshipId);
    if (metadata) metadataAdImpressions(metadata);
    impressionsService.addProductImpression(elem, sponsored, sponsorshipId);
  });
}
